<template>
    <v-dialog max-width="500px"  v-model="show">
    <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-card>
          <v-toolbar flat dark color="panel1">
            <v-toolbar-title>Add New Notification Setting</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="show=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
            <div class="pa-5">

                            <v-row no-gutters>
                    <v-col cols="12">

                <v-form ref="mainForm">
                    <v-text-field 
                        v-model="form.ns_code" label="Code" :rules="inputRules" 
                        outlined                          
                    ></v-text-field>
                    <v-text-field v-model="form.ns_name" label="Name" :rules="inputRules" outlined></v-text-field>
                    <v-text-field v-model="form.topic" label="FCM Topic" :rules="inputRules" outlined></v-text-field>
                    <v-spacer></v-spacer>
                </v-form>
                    </v-col>
                            </v-row>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cbtnSave" dark @click="save">
                    <v-icon left dark>mdi-check</v-icon>
                    Submit
                </v-btn>
                <v-btn class="ml-2" color="cbtnRed" dark @click="show=false">
                    <v-icon left dark>mdi-close</v-icon>
                    Close
                </v-btn>
            </v-card-actions>

          </v-card>
    </v-dialog>

</template>

<script>
import DlgMessage from '@/components/DlgMessage'
import ApNotificationService from "@/services/ApNotificationService"
export default {
    name: "DlgNotificationSettingAdd",
    props: {
        visible:Boolean,
    },      
    components:{DlgMessage},
    computed: {
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
                this.$emit('close')
           }
        }
      },
    },
    data(){
        return{
            dialog: false,
            form:{
                login_id: "",
                notification_setting_id: 0,
                ns_code: "",
                ns_name: "",
                topic: "",
                status: "ACTIVE"
            },
            error_message:'',
            error_code: '200',
            inputRules: [
             v => !!v || 'This field is required'
            ]
        }
    },
    async created() {
//        this.$refs.mainForm.reset()
      //this.viewMerchantEdc();
    },
     
    methods:{
   dlgShow(){
    },
    dlgClose(){
        //console.log(this.respCode);
        
        if(this.respCode == 200){
            this.form.c_code = "";
            this.form.c_name = "";
            this.form.sequence = 0;
            this.show = false;
        }
            
    },                
        async save(){
            //console.log(this.form);
            if(this.$refs.mainForm.validate()) {
                try{
                
                    ApNotificationService.insert_notification_setting(this.form).then(() => {
                        //console.log(res);
                        this.$refs.dlgMsg.showDlgBox(true,'success','New Notification Setting','Success.');
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','New Notification Setting',e.response.data.error_message);
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','New Notification Setting','Unknown Error');
                    
                } 
            }

        },
       
    }
}    

</script>